<template>
  <div class="privitePolicyWrapper">
    <div class="privitePolicyContainer">
      <p class="headerTitle">Privacy Policy</p>
      <section class="paragraph">
        Your privacy is important to us. We highly value and respect the privacy of the personal information we obtain
        from you by using our Site, App and/or Services (collectively, the "Service") and we have created this privacy
        policy (the "Privacy Policy") to inform you about how we collect, use and share your personal information, and
        our privacy practices in general.
      </section>
      <section class="paragraph">
        By accessing and using the Services, you agree to be bound by this Privacy Policy. If you do not agree to any of
        the provisions as set hereunder in this Privacy Policy, you have no right to use the Services. This Privacy
        Policy should be read together with the Terms of Service and together they constitute the entire agreement
        between you and us.
      </section>
      <section class="paragraph">
        This Privacy Policy applies to any and all Personal Information we may collect online including without
        limitations through our emails and/or by using any of our Services.
      </section>
      <section class="paragraph">
        This Privacy Policy may be updated from time to time and therefore we ask you to check back periodically to read
        the most updated Privacy Policy. Your continued use of the Services shall be deemed to be your continued
        acceptance of the terms of this Privacy Policy, as amended.
      </section>

      <p class="title">Information That We Collect</p>
      <section class="paragraph">
        The Personal Information we collect from customers helps provide you with our Services. Here are the types of
        information we gather:
      </section>
      <section class="paragraph">
        Information Provided by You. During your use of the Services, we may collect certain Personal Information
        delivered by you to us either as part of your registration to the Services or in any other way. This personal
        information may be updated by you from time to time. As a result of your use of the Services, you may supply us
        with such personal information as your name, e-mail address, uploaded content and metadata such as the source of
        that content (collectively, "Personal Information").
      </section>
      <section class="paragraph">
        Automatic Information. When using our Services, we may also collect certain analytical information that may
        identify you personally, including information that your browser or device generates, such as your IP address,
        your browser information (including browser type, capabilities and language), your operating system, the date
        and time you accessed the website, and the Service from which you linked to our Service ("Analytical
        Information", and together with the Personal Information, the "Information"). In addition, we may collect other
        information from you (such as location-based data) that have been stripped of your Information. We may group
        some or all this Information into aggregate data in order to describe the use of the Services to our existing or
        potential business partners, sponsors, advertisers or other third parties, or in response to a government
        request. We may also group demographic information that we collect from our users into aggregate data for the
        same purposes. We reserve the right to use and share any aggregate and anonymized Information with third parties
        for any lawful purpose. We may associate your Information with non-Personal Information within our database and
        we treat all the associated information as your protected Information under this Privacy Policy. However, except
        as noted below, we do not share this associated your Information with third parties.
      </section>

      <p class="title">Use of Information Collected</p>
      <section class="paragraph">
        Internal Use. We use Personal Information for internal purposes only, to improve our Service and to better
        tailor the Service to meet your needs. In addition, we use the Personal Information collected to analyze Service
        usage and to deliver information to you about certain promotions, services or items that might interest you. We
        may use your e-mail address to contact you regarding Service notices from time to time.
      </section>
      <section class="paragraph">
        Editing Information. You may review and edit any of your Information by using your e-mail address and password.
        If you cancel your account, your Personal Information shall be deleted from our database except for residual
        information within our databases, aggregate information access logs, backup logs and other records, which may or
        may not contain personally identifiable information.
      </section>
      <section class="paragraph">
        Resolving Problems. We may use your Personal Information and other data obtained from your current and past
        activities, to resolve disputes and troubleshoot problems.
      </section>
      <section class="paragraph">
        We will not sell, trade or rent your Personal Information to others unless you explicitly provide us with
        permission to do so.
      </section>

      <p class="title">Disclosure of Personal Information</p>

      <section class="paragraph">
        At Your Request. We will not disclose, sell or transfer any of your Information to any third parties, except if
        you request us to do so as part of the Services.
      </section>

      <section class="paragraph">
        Legal Disclosures. In the event we are required to disclose Information and other related information pursuant
        to lawful requests, such as subpoenas or court orders, or in compliance with applicable laws, we will make such
        disclosure.
      </section>

      <section class="paragraph">
        Sale of Business. In the event the ownership of all or a part of our business were to change, your Information
        and other related information pertinent to the business' operations would likely be transferred to, or licensed
        for use by, the new owner.
      </section>

      <section class="paragraph">
        To Protect the Services. We may disclose your Personal Information and other related information in the event we
        reasonably believe such disclosure is necessary to protect the Services, our rights or property, or the rights
        of any third party.
      </section>

      <section class="paragraph">
        Third Party Service Providers. We use third-party service providers to perform certain services on our behalf.
        We may provide your Information to these third-party service providers when the information is necessary for
        them to perform their duties. These third-party service providers are contractually restricted from using your
        Information for any other purpose and required to comply with all applicable laws with respect to your
        Information and other related information they receive.
      </section>

      <section class="paragraph">
        Special Cases. We may disclose your Information and other related information, in special cases when we have
        reason to believe that disclosing such information is necessary to identify, contact or bring legal action
        against someone who may be violating this Privacy Policy or may be causing injury to or interference with
        (either intentionally or unintentionally), our rights or property, other users, or anyone else that could be
        harmed by such activities. You thus authorize us to disclose any information about you to law enforcement or
        other government officials if we, in our sole and absolute discretion, believe that such disclosure is necessary
        or appropriate.
      </section>

      <p class="title">Sharing of Information</p>
      <section class="paragraph">
        Our Privacy Policy only addresses the use and disclosure of your Information we collect from you. To the extent
        that you disclose information to other parties by using the Services, different rules may apply to the use or
        disclosure of the Personal Information, which you disclose to them. We do not control the privacy policies of
        third parties and users will be subject to the privacy customs and policies of such third parties.
      </section>
      <p class="title">Protection of Information</p>
      <section class="paragraph">
        Information collected about you is protected in several ways. If you create an account, access by you to your
        Information is available through a password and unique user ID selected by you. Your password is encrypted. We
        recommend that you do not divulge your password to anyone. In addition, your personal information resides on
        proprietary secured servers that only selected personnel and contracts have access to via password.
      </section>
      <section class="paragraph">
        We use industry standard security measures to protect your Personal Information so that it is not made available
        to unauthorized parties. However, we cannot guarantee that the Personal Information submitted to, maintained on,
        or transmitted from our systems will be completely secure. If you have any questions regarding which measures
        and techniques we use, feel free to contact us at
        <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
          support@boolvector.com
        </a>
        .
      </section>

      <p class="title">Cookies</p>

      <section class="paragraph">
        The Site uses various types of cookies which help us administer and operate it, as further described in our
        <a href="https://boolv.tech/#/cookiesPolicy" _target="blanck">
          Cookies Policy
        </a>
      </section>

      <p class="title">Other Websites, Services and Communities</p>

      <section class="paragraph">
        Please be aware that when you are using the Services or our Site, you could be directed via hyperlinks or other
        means to third-party websites, services and online communities that are not affiliated with us. These websites,
        services and online communities may send their own cookies to visitors to collect data or solicit your Personal
        Information. The privacy policies of these websites, services and online communities may be very different from
        this Privacy Policy. We are not responsible for the privacy practices of these other websites, services and
        online communities and cannot guarantee the security of any of your Personal Information collected, used or
        shared there.
      </section>

      <p class="title">Policy Regarding Children</p>

      <section class="paragraph">
        You must be 13 years old or older to use the Site and the Services; we do not knowingly collect information from
        children under the age of 13. If we become aware that we have collected Personal Information from a child under
        the age of 13 years old, we will use commercially reasonable efforts to delete such information from our
        database. If you are between 13 and 17 years old, you agree that you received your parent or legal guardian's
        consent to use and access the Site and the Services.
      </section>

      <p class="title">European Citizens</p>

      <section class="paragraph">
        If you are a European resident, the following discolsures are made pursuant to the EU General Data Protection
        Regulations:
      </section>

      <p class="smallTitle">DATA CONTROLLER</p>
      <section class="paragraph">
        We are the data controller of the personal data we collect via the Service. Boolv Ltd. is the data controller of
        the personal data we collect via the Services and use and share as further described above. We are located at
        Hauteville Trust (BVI) Limited, P.O. Box 3483, Road Town, Tortola, British Virgin Islands.
      </section>
      <p class="smallTitle">REPRESENTATIVE IN THE EU</p>
      <section class="paragraph">
        The European representative of Boolv Ltd., for purposes of this Privacy Policy is
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">

          Boolv.tech
        </a>
        Spolka z ograniczona odpowiedzialnoscia (
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">

          Boolv.tech
        </a>
        Z.O.O), If you are within the European Economic Area, you may contact our European representative the at the
        following address: boolv (Cayman) Ltd.,103 South Church Street, P.O. Box 10240, Grand Cayman KY1-1002, Cayman
        Islands.
      </section>
      <p class="smallTitle">YOUR EU RIGHTS</p>

      <section class="paragraph">
        You have the right to access the personal information about you that we store on our systems, and have us
        update, correct or delete it. In addition, you may at any time withdraw your consent to any activity which you
        provided us your consent. You are also entitled to obtain from us your Information (excluding data we obtained
        from other sources) in a structured, commonly used and machine-readable format, and have the right to transmit
        those data to another data controller. If you wish to exercise any of these rights, contact us at:
        <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
          support@boolvector.com
        </a>
        .
      </section>
      <section class="paragraph">
        You can also have the right to lodge a complaint to the supervisory authority under the General data Protection
        Regulations ("GDPR"), in particular in the Member State of your residence, place of work or where the alleged
        infringement of the GDPR occured.
      </section>

      <p class="smallTitle">DATA RETENTION</p>

      <section class="paragraph">
        We retain personal data as long as we need it for the purposes for which it was obtained or until you ask to
        delete it. If you ask us to delete the Information, we may still have to retain it to comply with our legal
        obligations, resolve disputes and enforce our agreements
      </section>

      <p class="smallTitle">LEGAL BASIS FOR PROCESSING PERSONAL DATA:</p>
      <table class="table">
        <tr>
          <th>Information Type</th>
          <th>Purpose</th>
          <th>Legal Basis</th>
        </tr>
        <tr>
          <td>Processing Personal Information</td>
          <td>Online marketing and provision of the services</td>
          <td>
            The legal basis under EU law for processing and collecting your Personal Information is our legitimate
            interests in operating our Services.
          </td>
        </tr>
        <tr>
          <td>Processing Personal Information</td>
          <td>Profiled advertising</td>
          <td>
            The legal basis under EU law for collecting and processing your Personal Information for profiled
            advertising purposes is your explicit consent
          </td>
        </tr>
        <tr>
          <td>Processing Analytical Information</td>
          <td>Operation and enhancement</td>
          <td>
            The legal basis under EU law for processing and collecting Analytical Data is our legitimate interests in
            operating our Apps, ongoing management of our business and business development
          </td>
        </tr>
      </table>
      <p class="title">Questions</p>
      <section class="paragraph">
        You may direct questions concerning this Privacy Policy by email to
        <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
          support@boolvector.com
        </a>
        by adding the word 'privacy' in the subject line.
      </section>
    </div>
  </div>
</template>
<script setup>
import store from '../../store'
window.addEventListener("scroll", () => {
  store.dispatch('closeSide', { isCollapse: true })
});
</script>
<style scoped lang="less">
.privitePolicyWrapper {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  font-family: 'Helvetica Neue';
  text-align: left;
}

.privitePolicyContainer {
  max-width: 958px;
}

.headerTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 36px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
  padding-bottom: 24px;
}

.title {
  font-weight: 500;
  font-size: 26px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 24px;
}

.smallTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 12px;
}

.table {

  tr,
  td,
  th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
  }

  margin-bottom: 24px;
}

.anchorPoint {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #875eff;
  padding-bottom: 36px;
}

.linkName {
  color: #875eff;
}

.fontBold {
  font-weight: 500;
}

.listItem {
  padding-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.subListItem {
  text-indent: 1.5rem;
}

.subListItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

a {
  color: #875eff;

  &:hover {
    color: #a987ff;
  }
}
</style>